<template>
  <div>
    <div class="left-menus">
      <div class="menu-item">
        <div class="left-item" @click="changeLeftItem(1)">
          <span class="icon">
          <img src="@/assets/icons/profile.png"/>
            <!--          <img src="@/assets/icons/profile-active.png" />-->
        </span>
          <span class="title">My Profile</span>
        </div>

        <span class="down">
          <img src="@/assets/icons/up.png" v-if="showLeftItem.includes(1)"/>
          <img src="@/assets/icons/down.png" v-else/>
        </span>
      </div>
      <div class="menu-sub-items" v-if="showLeftItem.includes(1)">
        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'basic' }" @click="jumpTo('/user-profile/basic')">Basic Information</div>
        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'password' }" @click="jumpTo('/user-profile/advance')">Update Password</div>
      </div>
      <div class="menu-line"></div>
      <div class="menu-item" :class="{'menu-item-active' :activeMenu == 'my-product' }">
        <router-link to="/my-inquiries">
        <div class="left-item">
           <span class="icon">
          <img src="@/assets/icons/product.png"/>
             <!--          <img src="@/assets/icons/profile-active.png" />-->
        </span>
          <span class="title">My Products</span>
        </div>
        </router-link>

        <span class="down">

        </span>
      </div>
      <div class="menu-line"></div>
<!--      <div class="menu-item" @click="changeLeftItem(3)">-->
<!--        <div class="left-item">-->
<!--          <span class="icon">-->
<!--          <img src="@/assets/icons/company.png"/>-->
<!--            &lt;!&ndash;          <img src="@/assets/icons/profile-active.png" />&ndash;&gt;-->
<!--        </span>-->
<!--          <span class="title">Company Profile</span>-->
<!--        </div>-->

<!--        <span class="down">-->
<!--           <img src="@/assets/icons/up.png" v-if="showLeftItem.includes(3)"/>-->
<!--           <img src="@/assets/icons/down.png" v-else/>-->
<!--        </span>-->

<!--      </div>-->
<!--      <div class="menu-sub-items" v-if="showLeftItem.includes(3)">-->
<!--        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'company-basic' }" @click="jumpTo('/company-profile/basic')">Basic Information</div>-->
<!--        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'company-organization' }" @click="jumpTo('/company-profile/organization')"> Organization</div>-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'company-detail' }" @click="jumpTo('/company-profile/detail')">Company Details</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'company-detail' }" @click="jumpTo('/company-profile/detail')">Company Details</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'product' }" @click="jumpTo('/company-profile/products')">Products</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'member' }" @click="jumpTo('/company-profile/members')">Members</div>&ndash;&gt;-->
<!--        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'factory' }" @click="jumpTo('/company-profile/factories')">Factories</div>-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'document' }" @click="jumpTo('/company-profile/documents')">Documents</div>&ndash;&gt;-->
<!--        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'show' }" @click="jumpTo('/company-profile/shows')">-->
<!--          Show <span>New</span>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="menu-sub-item" :class="{'menu-sub-item-active' :activeMenu == 'webinar' }" @click="jumpTo('/company-profile/webinars')">&ndash;&gt;-->
<!--&lt;!&ndash;          Webinar <span>New</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

export default {
  props: [],
  computed: {

  },
  components: {  },

  data() {
    return {
      showLeftItem: [1,2,3],
      activeMenu: ''
    };
  },
  mounted() {
    if ("/user-profile/basic" === this.$route.path) {
      // this.showLeftItem = [1];
      this.activeMenu = 'basic';
    }
    if ("/user-profile/advance" === this.$route.path) {
      // this.showLeftItem = [1];
      this.activeMenu = 'password';
    }
    if ("/company-profile/basic" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'company-basic';
    }
    if ("/company-profile/detail" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'company-detail';
    }
    if ("/company-profile/members" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'member';
    }
    if ("/company-profile/factories" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'factory';
    }
    if ("/company-profile/members" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'member';
    }
    if ("/company-profile/products" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'product';
    }
    if ("/company-profile/shows" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'show';
    }
    if ("/company-profile/webinars" === this.$route.path) {
      // this.showLeftItem = [3];
      this.activeMenu = 'webinar';
    }
    if ("/my-inquiries" === this.$route.path) {
      this.activeMenu = 'my-product';
    }

  },
  watch: {

  },
  filters: {

  },
  methods: {
    jumpTo(path){
      this.$router.push(path);
    },
    changeLeftItem(item){
      console.log(item);
      if(this.showLeftItem.includes(item)){
        let index = this.showLeftItem.indexOf(item);
        if (index !== -1) {
          this.showLeftItem.splice(index, 1);
        }
      }else{
        this.showLeftItem.push(item);
      }
    },
  }
};
</script>
<style lang="less" scoped>
  .left-menus{
    background: #fff;
    width: 300px;
    border-radius: 15px;
    padding:  20px 0 20px 0;
    .menu-item{

      height: 52px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      .left-item{
        display: flex;
      }
      .icon{
        margin-right: 8px;
        img{
          width: 21px;
          padding-top: 2px;
        }
      }
      .down{
        /*margin-right: 8px;*/
        img{
          width: 18px;
          /*padding-top: 2px;*/
        }
      }
      .title{
        font-size: 17px;
        font-weight: 500;
      }
      .down{

      }
      a{
        color: #515a6e;
      }

    }
    .menu-sub-items{
      margin: 3px 0 5px 0;
      .menu-sub-item{
        color: rgba(36,28,21,0.8);
        padding: 8px 0 8px 50px;
        cursor: pointer;
        font-size: 15px;
        /*border-radius: 10px;*/
        &:hover{
          background: #f7f7f7;
        }
        span{
          background: #f9e0fa;
          border-radius: 5px;
          padding: 2px 4px;
          margin-left: 3px;
          font-size: 12px;
        }
      }
    }
    .menu-item-active{
      background: #efeeea;
      /*font-weight: 600;*/
    }
    .menu-line{
      background: #eee;
      height: 1px;
      width: 100%;
    }
  }
  .menu-sub-item-active{
    background: #efeeea;
  }
</style>
